<template lang="html">
  <div class="address">
    <div class="head">
      <img src="../../assets/image/addressBk.png" alt="">
    </div>
    <div class="title">
      添加/修改常驻地
    </div>
    <div class="box">
      <div class="list" @click="show = true">
        <div class="left">
          <span>*</span>
          选择地区
        </div>
        <div class="right">
          {{params.address ? params.address : '选择地区'}}
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="list">
        <div class="left">
          <span>*</span>
          详细地址
        </div>
        <div class="right">
          <van-field input-align="right" v-model="params.merchants_address" clearable placeholder="街道门牌信息" />
        </div>
      </div>
      <div class="list">
        <div class="left">
          邮政编码
        </div>
        <div class="right">
          <van-field input-align="right" v-model="params.postal" clearable placeholder="邮政编码" />
        </div>
      </div>
      <div class="btn" @click="confirm">
        <van-button type="primary" round>确定</van-button>
      </div>
    </div>
    <van-popup v-model:show="show" round position="bottom" :lock-scroll="false">
      <van-area title="省市区选择" :area-list="areaList" :value="codeValue" @cancel="show = false" @confirm="confirmArea" />
    </van-popup>
  </div>
</template>

<script>
  import { areaList } from '@vant/area-data';

  export default {
    name: 'addressList',
    data() {
      return {
        show: false,
        areaList,
        codeValue: '',
        params: {
          province: '',
          city: '',
          area: '',
          address: '',
          merchants_address: '',
          postal: ''
        }
      };
    },
    created() {
      this.getDetail();
    },
    methods: {
      getDetail() {
        this.$require.HttpPost('/h5/merchants/getMerchants').then(res => {
          if (res.data) {
            this.params = { ...res.data };
            if (res.data.province) {
              this.params.address = JSON.parse(res.data.province).name + JSON.parse(res.data.city).name + JSON.parse(res.data.area).name;
              this.codeValue = JSON.parse(res.data.area).code;
            }
          }
        });
      },
      confirmArea(event) {
        this.params.province = event[0];
        this.params.city = event[1];
        this.params.area = event[2];
        this.params.address = event[0].name + event[1].name + event[2].name;
        this.show = false;
      },
      confirm() {
        if (!this.params.address) {
          this.$toast('请选择省市区');
          return;
        }
        if (!this.params.merchants_address) {
          this.$toast('请输入详细地址');
          return;
        }
        this.$require.HttpPost('/h5/merchants/changeArea', this.params).then(res => {
          this.$toast(res.msg);
          setTimeout(() => {
            this.$router.go(-1);
          }, 500);
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .address {
    padding: 0 24px;
    .head {
      img {
        width: 348px;
        height: 268px;
        display: block;
        margin: 76px auto 0 auto;
      }
    }
    .title {
      font-size: 32px;
      color: #000000;
      padding: 0 30px;
      margin-top: 60px;
    }
    .box {
      box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
      border-radius: 30px;
      margin-top: 40px;
      padding-bottom: 60px;
      .list {
        height: 140px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        .left {
          font-size: 28px;
          color: #000000;
          span {
            color: #FF6238;
          }
        }
        .right {
          color: #333333;
          font-size: 28px;
          .van-icon {
            color: #B9B9B9;
          }
        }
      }
      .btn {
        width: 468px;
        height: 96px;
        margin: 60px auto 0 auto;
      }
    }
  }
</style>
